<template>
  <div class="error container">
    <div class="icons">🙈</div>
    <h2>Ups.. Página <b> no encontrada </b> </h2>
    <p>
      Es posible que la página no exista o que tenga un error en la
      dirección
    </p>

    <div class="button more">
  <img class="img" src="img/arrow_02.png" alt="flecha" />
      <router-link :to="{ path: '/' }">Ir a home</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "404",
};
</script>

<style lang="scss" scoped>
.error {

  height: 70vh;
  min-height: 600px;
  margin: 5rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .icons{
    font-size: 5rem;
    padding: 3rem;
  }

  h2 {
    text-align: center;

  }
  p{
    text-align: center;
  }

  .img {
    width: 4rem;
    height: auto;
  }
  a{
    padding: 1rem;
  }
}
</style>
